import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Group, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { memo, useState } from 'react';
import { AiOutlineFontSize } from 'react-icons/ai';
import { GrFormAdd, GrFormSubtract } from 'react-icons/gr';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_horizontal_cross_center, fill_vertical_all_center, jc, } from '~/modules/AppLayout/FlexGridCss';
/** 用於放大操作訊息文字的元件 */
export const MessageModal = memo(function MessageModal(props) {
    const [opened, { open, close }] = useDisclosure(false);
    const [fontSize, setFontSize] = useState(36);
    const theme = useThemeStore(t => t.theme);
    return (<styleds.container>
        <Modal css={css `
            &.mantine-Modal-root .mantine-Modal-content {
              height: 80%;
              width: 100%;
            }
          `} size='80%' opened={opened} onClose={close} centered title={false}>
          <styleds.header className={theme}>
            <div>{props.children}</div>
            <styleds.fontSettingContent>
              <AiOutlineFontSize size={32}/>
              <Button css={fontButtonCss} color='blue' onClick={() => {
            setFontSize(fontSize + 4);
        }}>
                <GrFormAdd size={32} css={iconCss}/>
              </Button>
              <Button css={fontButtonCss} color='blue' onClick={() => {
            setFontSize(fontSize - 4);
        }}>
                <GrFormSubtract size={32} css={iconCss}/>
              </Button>
              <div css={css `
                  font-size: 20px;
                  font-weight: 400;
                `}>
                {fontSize}px
              </div>
            </styleds.fontSettingContent>
          </styleds.header>
          <styleds.title size={fontSize} className={theme}>
            {props.time}
          </styleds.title>
          <styleds.body size={fontSize} className={theme}>
            {props.text}
          </styleds.body>
        </Modal>

        <Group position='left'>
          <styleds.openModalContent onClick={open} className={theme}>
            放大訊息視窗...
          </styleds.openModalContent>
        </Group>
      </styleds.container>);
});
const fontButtonCss = css `
  ${fill_vertical_all_center};
  width: 32px;
  height: 32px;
  font-size: 32px;
`;
const iconCss = css `
  & > * {
    stroke: #fff !important;
  }
`;
const styleds = {
    container: styled.div `
    width: auto;
    height: auto;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    height: 56px;
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    padding: 0 8px;
    border-radius: 6px;
    gap: 8px;
    &.dark {
      background-color: #454545;
      color: #dddddd;
    }
    &.light {
      background-color: #e1e1e1;
      color: #252525;
    }
  `,
    title: styled.div `
    font-size: ${props => props.size}px;
    font-weight: bold;
    padding: 4px;
    &.dark {
      color: #00ffea;
    }
    &.light {
      color: #0000aa;
    }
  `,
    body: styled.div `
    font-size: ${props => props.size}px;
    padding: 4px;
    word-wrap: break-word;
    white-space: pre-wrap;
    &.dark {
      color: #eeeeee;
    }
    &.light {
      color: #222222;
    }
  `,
    fontSettingContent: styled.div `
    display: flex;
    direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 4px;
  `,
    openModalContent: styled.div `
    text-decoration: underline;
    cursor: pointer;
    &.dark {
      color: #00ffea;
      &:hover {
        color: #00ffff;
      }
    }
    &.light {
      color: #0000aa;
      &:hover {
        color: #0022ff;
      }
    }
  `,
};
